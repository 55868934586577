import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import letterSvg from '../../../assets/letter.svg';
import './FlyAnimation.scss';
import SectionOne from './SectionOne/SectionOne';
import SectionTwo from './SectionTwo/SectionTwo';
import TestimonialsCarousel from './TestimonialsCarousel/TestimonialsCarousel';
import CallToAction from '../../CTA/CallToAction';
import InspirezVous from './InspirezVous/InspirezVous';
import MarketingOffers from '../../MarketingOffers/MarketingOffers';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

const FlyAnimation = () => {
  const fishRef = useRef(null);
  const pathRef = useRef(null);
  const shadowRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const settings = {
    shadowOpacity: 0.5,
    shadowBlur: 10,
    shadowYOffset: 40,
    rotationOffset: 100,
    initialPosition: 1,
    scrollStartOffset: -200,
    scrubSpeed: 10,
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) return;

    const startAnimation = () => {
      if (!fishRef.current || !pathRef.current || !shadowRef.current) {
        console.warn("GSAP target not found.");
        return;
      }

      const pathElement = pathRef.current;

      const resetAnimation = () => {
        gsap.set(fishRef.current, { rotation: settings.rotationOffset });
        gsap.set(shadowRef.current, {
          scaleY: 0.5,
          opacity: settings.shadowOpacity,
          filter: `blur(${settings.shadowBlur}px)`,
        });
      };

      resetAnimation();

      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: '.section-one',
          start: `top+=${settings.scrollStartOffset} top`,
          end: 'bottom+=1000px bottom',
          scrub: settings.scrubSpeed,
          immediateRender: true,
        },
      });

      timeline.to(fishRef.current, {
        motionPath: {
          path: pathElement,
          align: pathElement,
          alignOrigin: [0.8, 0.8],
          autoRotate: true,
        },
        ease: 'power2.inOut',
        immediateRender: true,
      });

      timeline.to(shadowRef.current, {
        motionPath: {
          path: pathElement,
          align: pathElement,
          alignOrigin: [0.8, 0],
          autoRotate: true,
        },
        ease: 'power2.inOut',
        immediateRender: true,
        y: settings.shadowYOffset,
      }, 0);

      timeline.seek(timeline.duration() * settings.initialPosition);

      return () => {
        timeline.kill();
        resetAnimation();
      };
    };

    const timeoutId = setTimeout(startAnimation, 100);
    return () => clearTimeout(timeoutId);
  }, [isMobile, settings]);

  return (
    <div className="animation-wrapper">
      {!isMobile && (
        <div className="fish-wrapper">
          <svg className="path-svg" viewBox="0 0 1920 1720" style={{ width: '1800px', height: '1800px', margin: '100px auto' }}>
            <defs>
              <linearGradient id="gradient-stroke" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#2ec4b6" stopOpacity="1" />
                <stop offset="100%" stopColor="#2ec4b6" stopOpacity="0" />
              </linearGradient>
            </defs>
            <circle cx="879.59" cy="186.06" r="25" fill="#2ec4b6" />
            <path
              ref={pathRef}
              d="M879.59,186.06C983.82,72.03,1133.78,.5,1300.45,.5c314.83,0,570.04,255.22,570.04,570.04s-255.22,570.04-570.04,570.04c-139.05,0-266.47-49.79-365.41-132.5l-.21,.25c-98.78-82.16-225.77-131.57-364.29-131.57C255.72,876.76,.5,1131.97,.5,1446.8c0,500.53,1203.46,1319.75,2308.53-47.1"
              stroke="url(#gradient-stroke)"
              strokeWidth="8"
              fill="none"
              strokeDasharray="22, 22"
            />
          </svg>
          <img src={letterSvg} alt="letter" ref={fishRef} className="fish" />
          <div ref={shadowRef} className="fish-shadow"></div>
        </div>
      )}

      <SectionOne />
      <SectionTwo />
      <InspirezVous />
      <MarketingOffers />
      <section className='Testimonial'>
        <TestimonialsCarousel />
      </section>
      <section>
        <CallToAction />
      </section>
    </div>
  );
};

export default FlyAnimation;