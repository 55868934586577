// ContactModalContext.js
import React, { createContext, useState, useContext } from 'react';
import ContactModal from './ContactModal'; // Assurez-vous d'importer le composant ContactModal

const ContactModalContext = createContext();

export const useContactModal = () => useContext(ContactModalContext);

export const ContactModalProvider = ({ children }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    return (
        <ContactModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            <ContactModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
        </ContactModalContext.Provider>
    );
};
