import React, { useContext, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './MarketingOffers.scss'; // Importation des styles pour ce composant
import { DataContext } from '../../context/DataContext'; // Importation du contexte de données

const MarketingOffers = () => {
    const { data, loading, error } = useContext(DataContext); // Récupération des données depuis le contexte
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(3); // Par défaut, 3 items à afficher

    useEffect(() => {
        // Autoplay : change de slide toutes les 3 secondes
        const interval = setInterval(() => {
            nextSlide();
        }, 3000);

        return () => clearInterval(interval); // Nettoyage à la désactivation du composant
    }, [currentIndex]);

    useEffect(() => {
        // Mise à jour du nombre d'items à afficher en fonction de la largeur de la fenêtre
        const updateItemsToShow = () => {
            if (window.innerWidth < 600) {
                setItemsToShow(1); // Mobile
            } else if (window.innerWidth < 1024) {
                setItemsToShow(2); // Tablette
            } else {
                setItemsToShow(3); // Desktop
            }
        };

        updateItemsToShow();
        window.addEventListener('resize', updateItemsToShow);

        return () => window.removeEventListener('resize', updateItemsToShow);
    }, []);

    if (loading) return <p>Chargement des données...</p>;
    if (error) return <p>Erreur lors de la récupération des données.</p>;

    const offers = data.acf?.nos_services?.offre_marketing || [];

    if (!offers || offers.length === 0) {
        return <p>Aucune offre disponible pour le moment.</p>;
    }

    // Configuration de la navigation du carrousel
    const nextSlide = () => {
        setCurrentIndex((prevIndex) => {
            const maxIndex = offers.length - itemsToShow;
            return prevIndex < maxIndex ? prevIndex + 1 : 0;
        });
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => {
            const maxIndex = offers.length - itemsToShow;
            return prevIndex > 0 ? prevIndex - 1 : maxIndex;
        });
    };
    const swipeConfidenceThreshold = 10000;
    const swipePower = (offset, velocity) => {
        return Math.abs(offset) * velocity;
    };
    // Déterminer si nous devons afficher les boutons de navigation
    const showNavigation = offers.length > itemsToShow;

    return (
        <div className="marketing-offers-container">
            <div className='container'>
                <h2 className="inspirez-vous-title frame-title-big left">
                    <span className="white-color">Nos Offres</span>
                    <span className="primary-color">Marketing</span>
                </h2>
                <div className="carousel">
                    {showNavigation && (
                        <button className="prev" onClick={prevSlide}>&#10094;</button>
                    )}
                    <motion.div
                        className="carousel-track"
                        initial={{ x: 0 }}
                        animate={{ x: `-${currentIndex * (100 / itemsToShow)}%` }}
                        transition={{ duration: 0.5 }}
                        drag="x"
                        dragConstraints={{ left: -((offers.length - itemsToShow) * (100 / itemsToShow)), right: 0 }}
                        dragElastic={0.1} // Ajoute une élasticité au drag
                        onDragEnd={(e, { offset, velocity }) => {
                            const swipe = swipePower(offset.x, velocity.x);
                            if (swipe < -swipeConfidenceThreshold) {
                                nextSlide();
                            } else if (swipe > swipeConfidenceThreshold) {
                                prevSlide();
                            }
                        }}
                    >
                        {offers.map((offer, index) => (
                            <motion.div
                                key={index}
                                className="offer-item-wrapper"
                                style={{ width: `${100 / itemsToShow}%` }}
                            >
                                <div className='offer-item'>
                                    <h3 className="offer-name">{offer.nom_de_loffre}</h3>
                                    <p className="offer-description">{offer.description_de_loffre}</p>
                                    <div
                                        className="offer-content"
                                        dangerouslySetInnerHTML={{ __html: offer.contenu_de_loffre }}
                                    />
                                    <p className="offer-price">{offer.prix}</p>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                    {showNavigation && (
                        <button className="next" onClick={nextSlide}>&#10095;</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MarketingOffers;
