import React, { useContext } from 'react';
import { ReactComponent as Logo } from '../../../../assets/logo_anim.svg';
import { DataContext } from '../../../../context/DataContext';
import ArgumentsSection from '../Agurments/ArgumentsSection';  // Section pour les arguments
import UnderSection from '../Under/UnderSection';  // Section pour le bloc "under"
import './SectionOne.scss';  // Styles pour SectionOne

const SectionOne = () => {
  const { data, loading, error } = useContext(DataContext);  // Récupère les données une seule fois

  if (loading) return <p>Chargement des données...</p>;
  if (error) return <p>Erreur : {error}</p>;
  if (!data || !data.acf || !data.acf.section_1) return <p>Aucune donnée disponible</p>;

  const argumentsSectionData = data.acf.section_1.arguments_section_1;
  const underArguments = data.acf.section_1.under_arguments;

  return (
    <section className="section section-one" aria-labelledby="section-one-title">
      <div className="container">
        <header className="logo-container" role="banner">
          <Logo className="logo" aria-label="Logo Courrier Papier" />
        </header>

        <ArgumentsSection argumentsData={argumentsSectionData} />

      </div>

      <UnderSection underData={underArguments} />
    </section>
  );
};

export default SectionOne;
