import React from 'react';

const Logo = () => {
  return (
    <div className="logo">
      <img 
        src={`${process.env.PUBLIC_URL}/assets/logo_menu.svg`}
        alt="Logo"
        onError={(e) => {
          console.error("Erreur de chargement du logo");
          e.target.style.display = 'none';
        }}
      />
    </div>
  );
};

export default Logo;