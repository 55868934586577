import React from 'react';
import { NavLink } from 'react-router-dom';  // Import de NavLink

const MenuLinks = ({ includeLegal, isFooter, onLinkClick }) => {
  return (
    <>
      {/* Afficher les liens principaux dans le footer et ailleurs */}
      <NavLink
        to="/"
        className={({ isActive }) => `menu-link ${isActive ? 'active' : ''}`}
        onClick={() => onLinkClick && onLinkClick()}  // Fermeture du menu après le clic
      >
        Accueil
      </NavLink>
      <NavLink
            to="/pourquoi"
            className={({ isActive }) => `menu-link ${isActive ? 'active' : ''}`}
            onClick={() => onLinkClick && onLinkClick()}  // Fermeture du menu après le clic
          >
            Pourquoi ?
          </NavLink>
      <NavLink
        to="/services"
        className={({ isActive }) => `menu-link ${isActive ? 'active' : ''}`}
        onClick={() => onLinkClick && onLinkClick()}  // Fermeture du menu après le clic
      >
        Services
      </NavLink>
      <NavLink
        to="/apropos"
        className={({ isActive }) => `menu-link ${isActive ? 'active' : ''}`}
        onClick={() => onLinkClick && onLinkClick()}  // Fermeture du menu après le clic
      >
        À propos
      </NavLink>

      {/* Afficher les mentions légales et politique de confidentialité uniquement dans le footer */}
      {isFooter && includeLegal && (
        <div className="footer-legal-links">
         
          <NavLink
            to="/mentions-legales"
            className={({ isActive }) => `menu-link ${isActive ? 'active' : ''}`}
            onClick={() => onLinkClick && onLinkClick()}  // Fermeture du menu après le clic
          >
            Mentions Légales
          </NavLink>
          <NavLink
            to="/politique-confidentialite"
            className={({ isActive }) => `menu-link ${isActive ? 'active' : ''}`}
            onClick={() => onLinkClick && onLinkClick()}  // Fermeture du menu après le clic
          >
            Politique de Confidentialité
          </NavLink>
        </div>
      )}
    </>
  );
};

export default MenuLinks;
