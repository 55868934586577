// PourquoiPage.js
import React, { useContext } from 'react';
import './PourquoiPage.scss'; // Importation des styles spécifiques pour cette page
import { DataContext } from '../../context/DataContext'; // Importation du DataContext
import CallToAction from '../CTA/CallToAction'; // Import du composant CallToAction

const PourquoiPage = () => {
    const { data } = useContext(DataContext);

    // Vérification si les données existent
    if (!data || !data.acf || !data.acf.pourquoi) {
        return <p>Aucune donnée disponible</p>; // Message d'erreur s'il n'y a pas de données
    }

    const content = data.acf.pourquoi;

    return (
        <section className="pourquoi-page">
            <div className="container">
                <div className="pourquoi-content" dangerouslySetInnerHTML={{ __html: content }} />
                
                {/* Ajout du composant CallToAction */}
            </div>

            <CallToAction />
        </section>
    );
};

export default PourquoiPage;
