import React from 'react';

export const StepOne = ({ formData, handleChange, nextStep, validateStep }) => (
  <div className="form-group">
    <h2>Étape 1 : Informations Personnelles</h2>
    <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Nom" required />
    <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
    <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Numéro de téléphone" required />
    <input type="text" name="company" value={formData.company} onChange={handleChange} placeholder="Nom de l'entreprise" required />
    <select name="companySize" value={formData.companySize} onChange={handleChange}>
      <option value="">Taille de l'entreprise</option>
      <option value="petite">Petite</option>
      <option value="moyenne">Moyenne</option>
      <option value="grande">Grande</option>
    </select>
    <button type="button" onClick={() => { if (validateStep()) nextStep(); }}>Suivant</button>
  </div>
);

export const StepTwo = ({ formData, handleChange, prevStep, nextStep, validateStep }) => (
  <div className="form-group">
    <h2>Étape 2 : Type de Projet</h2>
    <select name="projectType" value={formData.projectType} onChange={handleChange} required>
      <option value="">Sélectionner un type de projet</option>
      <option value="campagne-marketing">Campagne de marketing ciblée</option>
      <option value="invitation-evenement">Invitation à des événements</option>
      <option value="catalogue-personnalise">Envoi de catalogues personnalisés</option>
      <option value="relance-abonnement">Relance d'abonnements</option>
    </select>
    <button type="button" onClick={prevStep}>Précédent</button>
    <button type="button" onClick={() => { if (validateStep()) nextStep(); }}>Suivant</button>
  </div>
);

export const StepThree = ({ formData, handleChange, prevStep, nextStep }) => (
  <div className="form-group">
    <h2>Étape 3 : Détails du Projet</h2>
    <input type="number" name="quantity" value={formData.quantity} onChange={handleChange} placeholder="Quantité d'envois" />
    <button type="button" onClick={prevStep}>Précédent</button>
    <button type="button" onClick={nextStep}>Suivant</button>
  </div>
);

export const StepFour = ({ formData, handleChange, prevStep, handleSubmit }) => (
  <div className="form-group">
    <h2>Étape 4 : Message Final</h2>
    <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Votre message" />
    <button type="button" onClick={prevStep}>Précédent</button>
    <button type="submit" onClick={handleSubmit}>Envoyer</button>
  </div>
);

export const SuccessStep = () => (
  <div className="success-message">
    <h2>Merci pour votre message !</h2>
    <p>Votre formulaire a été correctement envoyé. Nous vous contacterons bientôt.</p>
  </div>
);