import React, { useContext } from 'react';
import './NosServices.scss'; // Import du fichier SCSS pour les styles spécifiques à la page
import CallToAction from '../../CTA/CallToAction'; // Import du composant CallToAction
import ServiceIntro from './ServiceIntro'; // Import du nouveau composant ServiceIntro
import FrameContainer from '../../Tools/FrameItem/FrameContainer'; // Import du FrameContainer
import { DataContext } from '../../../context/DataContext'; // Import du DataContext
import MarketingOffers from '../../MarketingOffers/MarketingOffers'; // Assure-toi du chemin d'import correct
import { ReactComponent as HeaderSVG } from '../../../assets/header_animated.svg';
const NosServices = () => {
    const { data } = useContext(DataContext); // Utilisation du contexte pour récupérer les données

    const servicesData = data?.acf?.nos_services?.nos_services_items; // Récupération des services depuis le JSON
    const introData = data?.acf?.nos_services?.nos_servies_infos; // Récupération des infos d'intro depuis le JSON

    return (
        <div className="nos-services-wrapper">
            {/* Section d'introduction dynamique */}
            {introData && (
                <section className="service-intro">
                    <div className="container">
                        <div className='headContainer'>
                            <div className="headhleft">
                                <HeaderSVG style={{ width: '100%', height: 'auto' }} />
                                {/* Autres contenus */}
                            </div>
                            <div className='headright'>
                                <h1 className="frame-title-big left">
                                    {introData.titre?.split(' ').slice(0, 2).join(' ') && (
                                        <span className="white-color">{introData.titre.split(' ').slice(0, 2).join(' ')}</span>
                                    )}
                                    <span className="primary-color">
                                        {introData.titre?.split(' ').slice(2).join(' ')}
                                    </span>
                                </h1>
                                <hr></hr>
                                <h2 id="nos-services-title">{introData?.accroche || "Nos Services"}</h2>
                                <hr></hr>
                                <p>{introData?.description}</p>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {/* Section des services dynamiques */}
            <section className="nos-services" aria-labelledby="nos-services-title">
                <div className="container">


                    <div className="services-list">
                        {servicesData && servicesData.length > 0 ? (
                            <FrameContainer items={servicesData} />
                        ) : (
                            <p>Aucun service disponible pour le moment.</p>
                        )}
                    </div>
                </div>
            </section>

            {/* Inclusion du composant CallToAction */}
            <section className="">
                <MarketingOffers />
            </section>
            {/* Inclusion du composant CallToAction */}
            <section className="call-to-action-section">
                <CallToAction />
            </section>
        </div>
    );
};

export default NosServices;
