import React, { useState, useContext, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { StepOne, StepTwo, StepThree, StepFour, SuccessStep } from './ContactFormSteps';
import { DataContext } from '../../../context/DataContext';
import './ContactForm.scss'; // N'oubliez pas de créer ce fichier CSS

const ContactForm = () => {
  const { globalData } = useContext(DataContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    companySize: '',
    projectType: '',
    quantity: '',
    message: '',
  });
  const [sendToEmail, setSendToEmail] = useState('');

  useEffect(() => {
    if (globalData?.acf?.option_du_site?.footer_infos?.contact) {
      setSendToEmail(globalData.acf.option_du_site.footer_infos.contact);
    }
  }, [globalData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  const validateStep = () => {
    if (currentStep === 1 && (!formData.name || !formData.email || !formData.company)) {
      alert("Veuillez remplir le nom, l'email et le nom de l'entreprise.");
      return false;
    }
    if (currentStep === 2 && !formData.projectType) {
      alert("Veuillez sélectionner un type de projet.");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const serviceID = 'service_93rvfzp';
    const templateID = 'template_2b716tq';
    const userID = 'cUI72jlGlSb41ALZq';

    const emailData = {
      name: formData.name,
      email: formData.email,
      company: formData.company,
      companySize: formData.companySize,
      projectType: formData.projectType,
      quantity: formData.quantity,
      message: formData.message,
      reply_to: formData.email,
    };

    emailjs.send(serviceID, templateID, emailData, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.error('FAILED...', error);
        alert(`Erreur lors de l'envoi du formulaire : ${error.text}`);
      });
  };

  return (
    <div className="contact">
        {!isSubmitted ? (
          <form onSubmit={handleSubmit} className="contact-form">
            {currentStep === 1 && <StepOne formData={formData} handleChange={handleChange} nextStep={nextStep} validateStep={validateStep} />}
            {currentStep === 2 && <StepTwo formData={formData} handleChange={handleChange} prevStep={prevStep} nextStep={nextStep} validateStep={validateStep} />}
            {currentStep === 3 && <StepThree formData={formData} handleChange={handleChange} prevStep={prevStep} nextStep={nextStep} />}
            {currentStep === 4 && <StepFour formData={formData} handleChange={handleChange} prevStep={prevStep} handleSubmit={handleSubmit} />}
          </form>
        ) : (
          <SuccessStep />
        )}
    </div>
  );
};

export default ContactForm;