import React, { useContext } from 'react';
import './PolitiqueConfidentialite.scss'; // Import du fichier SCSS pour les styles spécifiques
import { DataContext } from '../../../context/DataContext'; // Import du contexte

const PolitiqueConfidentialite = () => {
  const { data } = useContext(DataContext); // Utilisation du contexte pour récupérer les données

  let politiqueConfidentialite = data?.acf?.option_du_site?.politique_de_confidentialite; // Récupération des données

  // Si la politique de confidentialité existe, on remplace les sauts de ligne par des balises <br/>
  if (politiqueConfidentialite) {
    politiqueConfidentialite = politiqueConfidentialite.replace(/\r\n/g, '<br/>');
  }

  return (
    <section className="politique-confidentialite-section">
      <div className="container">
        <header>
          <h1 className="frame-title-big left">Politique de Confidentialité</h1>
        </header>

        <div className="content">
          {politiqueConfidentialite ? (
            <div dangerouslySetInnerHTML={{ __html: politiqueConfidentialite }} />
          ) : (
            <p>La politique de confidentialité est actuellement indisponible.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default PolitiqueConfidentialite;
