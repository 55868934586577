import React from 'react';
import { NavLink } from 'react-router-dom'; // Import de NavLink pour la navigation
import Button from '../Tools/Button/Button';

const ContactButton = () => {
  return (
    <NavLink to="/contact" aria-label="Contactez Courrier Papier">
      <Button variant="secondary">
        Contact
      </Button>
    </NavLink>
  );
};

export default ContactButton;
