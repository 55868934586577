import React, { useContext } from 'react';
import './MentionsLegales.scss'; // Import du fichier SCSS pour les styles spécifiques
import { DataContext } from '../../../context/DataContext'; // Import du DataContext

const MentionsLegales = () => {
  const { data } = useContext(DataContext); // Utilisation du contexte pour récupérer les données

  let mentionsLegales = data?.acf?.option_du_site?.mentons_legales;

  // Si le texte des mentions légales existe, on remplace les sauts de ligne par des balises <br/>
  if (mentionsLegales) {
    mentionsLegales = mentionsLegales.replace(/\r\n/g, '<br/>');
  }

  return (
    <section className="mentions-legales-section">
      <div className="container">
        <header>
          <h1 className="frame-title-big left">Mentions Légales</h1> {/* Utilisation du style .frame-title-big */}
        </header>

        <div className="content">
          {mentionsLegales ? (
            <div dangerouslySetInnerHTML={{ __html: mentionsLegales }} />
          ) : (
            <p>Les mentions légales sont actuellement indisponibles.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default MentionsLegales;
