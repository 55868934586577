import React, { useContext } from 'react';
import './WhatsAppChat.scss'; // Import du fichier SCSS pour le style du bouton WhatsApp
import { DataContext } from '../context/DataContext'; // Import du contexte pour accéder aux données

const WhatsAppChat = () => {
  const { data } = useContext(DataContext); // Récupère les données depuis le DataContext
  const phoneNumber = data?.acf?.option_du_site?.numeros_de_telephone; // Récupère le numéro de téléphone depuis les données

  // Nettoie le numéro en supprimant les espaces, parenthèses, tirets, etc.
  const cleanPhoneNumber = phoneNumber.replace(/\s+/g, '').replace(/[^0-9+]/g, '');

  const openWhatsApp = () => {
    const url = `https://wa.me/${cleanPhoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <div className="whatsapp-chat" onClick={openWhatsApp} aria-label="Contactez-nous sur WhatsApp">
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </div>
  );
};

export default WhatsAppChat;
