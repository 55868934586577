import React from 'react';
import FrameContainer from '../../../Tools/FrameItem/FrameContainer';  // Import du nouveau composant

const ArgumentsSection = ({ argumentsData }) => {
  if (!argumentsData || argumentsData.length === 0) {
    return <p>Aucune donnée disponible</p>;
  }

  return (
    <FrameContainer items={argumentsData} />  
  );
};

export default ArgumentsSection;
