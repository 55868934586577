import React from 'react';

const FrameItem = React.forwardRef(({ item, index, showBorder }, ref) => {
  return (
    <article
      ref={ref}
      className="frame-item"
      aria-labelledby={`frame-title-${index}`}
    >
      <div className="icon" role="img" aria-label={`Icône ${item?.titre?.toLowerCase() || 'icon'}`}>
        {item?.icon && <img src={item.icon} alt={item?.titre || 'Icone'} />}
        {showBorder && <div className="icon-border"></div>}
      </div>
      <div className="frame-text">
        {item?.titre && (
          <h3 id={`frame-title-${index}`} className="frame-title">
            {item.titre}
          </h3>
        )}
        {item?.accroche && (
          <p className="frame-accroche">{item.accroche}</p> 
        )}

        {/* Si point_fort est présent, l'afficher, sinon afficher la description */}
        {item?.point_fort ? (
          <>
            <p className='bold color'>+ Points forts</p>
            <div className="frame-description" dangerouslySetInnerHTML={{ __html: item.point_fort }} />
          </>
        ) : (
          <div className="frame-description" dangerouslySetInnerHTML={{ __html: item?.description || 'Description non disponible.' }} />
        )}
      </div>
    </article>
  );
});

export default FrameItem;
