import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './LoadingPage.scss'; // Styles du loader
import { ReactComponent as LogoLoader } from '../assets/logo_loader.svg'; // Utilisation de ReactComponent pour le SVG

const LoadingPage = ({ isLoading }) => {
  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          className="loading-overlay"
          key="loading"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.div
            className="logo-container"
            initial={{ y: '50px' }}
            animate={{ y: '0%' }}
            exit={{ y: '-25px' }}
            transition={{ duration: .20 }}
          >
            <LogoLoader aria-label="Logo Courrier Papier" />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingPage;
