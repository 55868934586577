import React from 'react';
import { Link } from 'react-router-dom';
import './UnderSection.scss'; // Import du fichier SCSS pour le style
import Button from '../../../Tools/Button/Button'; 
 // Import du bouton uniformisé
const UnderSection = ({ underData }) => {
    if (!underData) return null;  // Ne pas afficher la section si les données sont manquantes

    const { titre, description } = underData;

    // Séparation des deux premiers mots du titre
    const firstTwoWords = titre.split(' ').slice(0, 2).join(' ');
    const restOfTitle = titre.split(' ').slice(2).join(' ');

    // Séparation de la première phrase de la description
    const firstSentence = description.split('.')[0] + '.'; // La première phrase jusqu'au premier point
    const restOfDescription = description.split('.').slice(1).join('.'); // Le reste du texte après le premier point

    return (
        <div className="under-wrapper">
            <div className="under">
                <div className="container">
                    <div className="frame-row">
                        <div className="frame">
                            <h1 className="frame-title-big" id="section-one-title">
                                <span className="primary-color">{firstTwoWords}</span>{' '}
                                <span className="white-color">{restOfTitle}</span>
                            </h1>
                            <div className="frame-text left">
                                <p>
                                    <span className="primary-color" style={{ fontWeight: 'bold' }}>{firstSentence}</span>
                                    <br /> {/* Retour à la ligne */}
                                    <span>{restOfDescription.trim()}</span>
                                </p>
                                <div className="frame-item button-item">
                                    {/* Utilisation du composant Button en conservant le Link */}
                                    <Link to="/pourquoi" aria-label="Plus d'infos sur Courrier Papier">
                                        <Button variant="primary">+ d’infos</Button>
                                        
                                    </Link>
                                    
                                    <Link to="/contact" aria-label="Contactez Courrier Papier">
                                        <Button variant="secondary">Contact</Button>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnderSection;
