import React, { useContext, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './TestimonialsCarousel.scss';  // Import de ton propre fichier SCSS
import { DataContext } from '../../../../context/DataContext'; // Contexte

const TestimonialsCarousel = () => {
    const { data, loading, error } = useContext(DataContext); // Récupération des données via le contexte
    const testimonialsData = data?.acf?.testimonial?.testimonial_item || []; // Récupère les témoignages depuis le JSON
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        // Autoplay : change de témoignage toutes les 5 secondes
        const interval = setInterval(() => {
            nextTestimonial();
        }, 5000);

        return () => clearInterval(interval); // Nettoyage à la désactivation du composant
    }, [currentIndex]);

    if (loading) return <p>Chargement des témoignages...</p>;
    if (error) return <p>Erreur : {error}</p>;
    if (!testimonialsData.length) return <p>Aucun témoignage disponible</p>;

    // Navigation vers le prochain témoignage
    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
    };

    // Navigation vers le témoignage précédent
    const prevTestimonial = () => {
        setCurrentIndex((prevIndex) => 
            prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="carousel-container">
            <header>
                <h1 id="service-intro-title" className="frame-title-big center">
                    <span className="primary-color">Témoignages</span>
                    <span className="white-color" style={{ marginLeft: 10 }}> Clients</span>
                </h1>
            </header>
            <div className="testimonial-slider">
                <button className="prev" onClick={prevTestimonial}>&#10094;</button>
                <AnimatePresence mode="wait">
                    <motion.div 
                        key={currentIndex}
                        className="testimonial"
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -100 }}
                        transition={{ duration: 0.5 }}
                    >
                        <p className="feedback">"{testimonialsData[currentIndex].commentaire}"</p>
                        <h3 className="name">{testimonialsData[currentIndex].nom}</h3>
                        <p>{testimonialsData[currentIndex].fonction}</p>
                    </motion.div>
                </AnimatePresence>
                <button className="next" onClick={nextTestimonial}>&#10095;</button>
            </div>
        </div>
    );
};

export default TestimonialsCarousel;
