import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import FlyAnimation from './components/Pages/Home/FlyAnimation';
import NosServices from './components/Pages/NosServices/NosServices';
import APropos from './components/Pages/Apropos/Apropos';
import Contact from './components/Pages/Contact/Contact';
import MentionsLegales from './components/Pages/MentionsLegales/MentionsLegales';
import PolitiqueConfidentialite from './components/Pages/PolitiqueConfidentialite/PolitiqueConfidentialite';
import PourquoiPage from './components/Pourquoi/PourquoiPage';
import WhatsAppChat from './components/WhatsAppChat';
import LoadingPage from './components/LoadingPage';
import CookiesBar from './components/CookiesBar/CookiesBar';
import ScrollToTop from './components/ScrollToTop';
import { DataProvider, DataContext } from './context/DataContext';
import { ContactModalProvider } from './context/ContactModalContext';
import './App.scss';
import './styles/globals.scss';

const AppContent = () => {
  const { loading: dataLoading } = useContext(DataContext);
  const [allLoaded, setAllLoaded] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkResourcesLoaded = () => {
      if (document.readyState === 'complete') {
        setAllLoaded(true);
      }
    };

    checkResourcesLoaded();
    window.addEventListener('load', checkResourcesLoaded); // Utiliser l'événement "load"
    return () => window.removeEventListener('load', checkResourcesLoaded);
  }, []);

  useEffect(() => {
    console.log("Data loading state:", dataLoading);
    console.log("All resources loaded:", allLoaded);
    console.log("Display loader:", displayLoader);
    if (!dataLoading && allLoaded) {
      const timer = setTimeout(() => {
        setDisplayLoader(false); // Lancer la sortie du loader
      }, 500); // Permettre une transition de 500ms pour l'animation

      return () => clearTimeout(timer); // Nettoyer le timer
    }
  }, [dataLoading, allLoaded]);

  return (
    <AnimatePresence>
      {displayLoader ? (
        <LoadingPage key="loading" isLoading={displayLoader} />
      ) : (
        <motion.div key="app" className="App" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <ScrollToTop />
          <Header />
          <div className="page-content"> {/* Ajout d'une classe ici */}
            <AnimatePresence mode="wait" className="animate-presence-container">
              <Routes location={location} key={location.pathname}>
                <Route path="/" element={<PageTransition><FlyAnimation /></PageTransition>} />
                <Route path="/services" element={<PageTransition><NosServices /></PageTransition>} />
                <Route path="/apropos" element={<PageTransition><APropos /></PageTransition>} />
                <Route path="/contact" element={<PageTransition><Contact /></PageTransition>} />
                <Route path="/mentions-legales" element={<PageTransition><MentionsLegales /></PageTransition>} />
                <Route path="/politique-confidentialite" element={<PageTransition><PolitiqueConfidentialite /></PageTransition>} />
                <Route path="/pourquoi" element={<PageTransition><PourquoiPage /></PageTransition>} />
              </Routes>
            </AnimatePresence>
          </div>
          <Footer />
          <WhatsAppChat />
          <CookiesBar />
        </motion.div>
      )}
    </AnimatePresence>
  );
};


const PageTransition = ({ children }) => (
  <motion.div
    style={{ width: '100%' }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.30 }}
  >
    {children}
  </motion.div>
);

const App = () => {
  return (
    <DataProvider>
      <ContactModalProvider>
        <Router basename="/">
          <AppContent />
        </Router>
      </ContactModalProvider>
    </DataProvider>
  );
};

export default App;
