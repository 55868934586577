import React, { useContext } from 'react';
import { FaInstagram, FaFacebookF } from 'react-icons/fa';  // Import correct des icônes
import { DataContext } from '../../context/DataContext';  // Import du contexte pour accéder aux données
import './SocialMediaIcons.scss';

const SocialMediaIcons = () => {
    const { data } = useContext(DataContext); // Récupérer les données à partir du DataContext
    const { instagram, facebook } = data?.acf?.option_du_site?.reseaux_sociaux || {};  // Récupérer les URLs Instagram et Facebook

    return (
        <div className="social-media-icons">
            {/* Affichage dynamique du lien Instagram si présent */}
            {instagram && (
                <a href={instagram} className="icon_link" target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={34} />  {/* Icône Instagram */}
                </a>
            )}
            
            {/* Affichage dynamique du lien Facebook si présent */}
            {facebook && (
                <a href={facebook} className="icon_link" target="_blank" rel="noopener noreferrer">
                    <FaFacebookF size={34} />  {/* Icône Facebook */}
                </a>
            )}
        </div>
    );
};

export default SocialMediaIcons;
