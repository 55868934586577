import React, { useContext } from 'react';
import './SectionTwo.scss';
import { DataContext } from '../../../../context/DataContext';
import FrameContainer from '../../../Tools/FrameItem/FrameContainer';  // Import du composant réutilisable
import Button from '../../../Tools/Button/Button';  // Import du bouton uniformisé
import { Link } from 'react-router-dom';  // Garder la navigation via Link

const SectionTwo = () => {
  const { data, loading, error } = useContext(DataContext);

  if (loading) return <p>Chargement des données...</p>;
  if (error) return <p>Erreur : {error}</p>;
  if (!data || !data.acf || !data.acf.section_2 || !data.acf.section_2.items) {
    return <p>Aucune donnée disponible</p>;
  }

  const servicesData = data.acf.section_2.items;

  return (
    <section className="section section-two">
      <div className="container">
        <div className="frame-title-big">
          <span className='primary-color'>Aperçu</span> <span className='white-color' style={{ marginLeft: 10 }}> des Services</span>
        </div>

        {/* Utilisation du composant FrameContainer avec le bouton passé en extraContent */}
        <FrameContainer
          className="noBorder" // Ajout de la classe ici
          items={servicesData}
          extraContent={
            <article className="frame-item button-item">
              {/* Utilisation du composant Button en conservant le Link */}
              <Link to="/services" aria-label="Plus d'infos sur Courrier Papier">
                <Button variant="primary">Découvrez Tous Nos Services</Button>
              </Link>
            </article>
          }
        />

      </div>
    </section>
  );
};

export default SectionTwo;
