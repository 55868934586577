import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animate } from 'framer-motion'; // Import de framer-motion

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      const scrollY = window.scrollY; // Position actuelle du scroll
      animate(scrollY, 0, {
        duration: 0.8, // Durée de l'animation
        onUpdate: (v) => window.scrollTo(0, v), // Mise à jour de la position du scroll
      });
    };
    
    scrollToTop(); // Appelle la fonction pour lancer l'animation
  }, [pathname]); // Déclenché à chaque changement de route

  return null;
};

export default ScrollToTop;
