// Contact.js
import React from 'react';
import './Contact.scss';
import ContactForm from './ContactForm';  // Importez le nouveau composant ContactForm

const Contact = () => {
  return (
    <>
      <section className="contactSection">
        <div className="contact-container">
          <div className='content'>
            <div className='container'>
              <div className='partLeft'>
                <h1 className="frame-title-big left">Nous Contacter</h1>
                <p>
                  Vous avez des questions ou souhaitez en savoir plus sur nos services ? Remplissez le formulaire ci-dessous et nous vous répondrons rapidement.
                </p>
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
      </section>

    </>
  );
};

export default Contact;