import React, { useState, useEffect } from 'react';

const OpeningHours = ({ footerInfos }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openingDaysText, setOpeningDaysText] = useState('');

  // Fonction pour convertir une chaîne de format "HH:MM:SS" en un objet Date
  const timeStringToDate = (timeString) => {
    if (!timeString) return null;
    const [hours, minutes] = timeString.split(':');
    const now = new Date();
    now.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
    return now;
  };

  // Fonction pour formater les heures en hh:mm
  const formatTime = (timeString) => {
    if (!timeString) return 'Fermé';
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  };

  // Fonction pour détecter des jours consécutifs
  const getConsecutiveDays = (days) => {
    const dayOrder = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
    const dayIndices = days.map((day) => dayOrder.indexOf(day.toLowerCase())).sort((a, b) => a - b);

    let consecutiveGroups = [];
    let tempGroup = [dayOrder[dayIndices[0]]];

    for (let i = 1; i < dayIndices.length; i++) {
      if (dayIndices[i] === dayIndices[i - 1] + 1) {
        tempGroup.push(dayOrder[dayIndices[i]]);
      } else {
        consecutiveGroups.push(tempGroup);
        tempGroup = [dayOrder[dayIndices[i]]];
      }
    }
    consecutiveGroups.push(tempGroup);

    return consecutiveGroups
      .map((group) => (group.length > 1 ? `du ${capitalize(group[0])} au ${capitalize(group[group.length - 1])}` : capitalize(group[0])))
      .join(', ');
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  useEffect(() => {
    const {
      horaire_douverture_matin,
      horaire_de_fermeture_matin,
      horaire_douverture_apres_midi,
      horaire_de_fermeture_apres_midi,
      jour_douverture
    } = footerInfos;

    const currentDate = new Date();
    const currentDay = currentDate.toLocaleDateString('fr-FR', { weekday: 'long' }).toLowerCase();
    const normalizedOpeningDays = jour_douverture.map((day) => day.toLowerCase());
    const isTodayOpen = normalizedOpeningDays.includes(currentDay);

    if (isTodayOpen) {
      const openingMorning = timeStringToDate(horaire_douverture_matin);
      const closingMorning = timeStringToDate(horaire_de_fermeture_matin);
      const openingAfternoon = timeStringToDate(horaire_douverture_apres_midi);
      const closingAfternoon = timeStringToDate(horaire_de_fermeture_apres_midi);

      const isMorningOpen = currentDate >= openingMorning && currentDate <= closingMorning;
      const isAfternoonOpen =
        openingAfternoon && closingAfternoon
          ? currentDate >= openingAfternoon && currentDate <= closingAfternoon
          : false;

      setIsOpen(isMorningOpen || isAfternoonOpen);
    } else {
      setIsOpen(false);
    }

    // Générer l'affichage des jours d'ouverture consécutifs
    const openingDaysDisplay = getConsecutiveDays(normalizedOpeningDays);
    setOpeningDaysText(`Ouvert ${openingDaysDisplay}`);
  }, [footerInfos]);

  return (
    <div className="footer-opening-hours">
      <h4>Horaires d'ouverture :</h4>
      <p>
        {formatTime(footerInfos.horaire_douverture_matin)} - {formatTime(footerInfos.horaire_de_fermeture_matin)} /{' '}
        {footerInfos.horaire_douverture_apres_midi && footerInfos.horaire_de_fermeture_apres_midi
          ? `${formatTime(footerInfos.horaire_douverture_apres_midi)} - ${formatTime(footerInfos.horaire_de_fermeture_apres_midi)}`
          : 'Fermé'}
      </p>
      <p>{openingDaysText}</p>
      <p className={`footer-status ${isOpen ? 'open' : 'closed'}`}>
        {isOpen ? 'Ouvert actuellement' : 'Fermé actuellement'}
      </p>
    </div>
  );
};

export default OpeningHours;
