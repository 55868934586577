import React, { useState, useEffect } from 'react';
import Logo from './Logo';
import MenuLinks from './MenuLinks';
import SocialMediaIcons from './SocialMediaIcons';
import ContactButton from './ContactButton';
import './Header.scss';
import { Link } from 'react-router-dom';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    // Détection de la résolution pour déterminer si on est sur mobile ou desktop
    const updateWindowDimensions = () => {
        setIsMobile(window.innerWidth <= 768); // Par exemple, < 768px pour mobile
    };

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        if (!menuOpen) {
            document.body.style.overflow = 'hidden'; // Désactiver le scroll quand le menu est ouvert
        } else {
            document.body.style.overflow = ''; // Réactiver le scroll
        }
    };

    const closeMenu = () => {
        setMenuOpen(false);
        document.body.style.overflow = ''; // Réactiver le scroll
    };

    return (
        <div className="header-container">
            <header className="header">
                <Link to="/" onClick={closeMenu}>
                    <Logo />
                </Link>
                {/* Affichage du menu en fonction de la résolution */}
                <nav className={`menu-links ${menuOpen ? 'menu-open' : ''}`}>
                    <MenuLinks onLinkClick={closeMenu} isFooter={false} />
                </nav>

                {/* Overlay en arrière-plan pour le mobile */}
                {menuOpen && <div className="menu-overlay" onClick={closeMenu}></div>}
                {!isMobile && <SocialMediaIcons />}
                <ContactButton />
                <div className="rightMenu">


                    {isMobile && (
                        <div className={`hamburger ${menuOpen ? 'is-active' : ''}`} onClick={toggleMenu}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    )}
                </div>
            </header>
        </div>
    );
};

export default Header;
