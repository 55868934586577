import React, { useContext } from 'react';
import { ReactComponent as LogoFooter } from '../../assets/logo_footer.svg';
import MenuLinks from '../Header/MenuLinks'; // Utilisation du composant MenuLinks centralisé
import { DataContext } from '../../context/DataContext';
import OpeningHours from './OpeningHours'; // Import du composant OpeningHours
import './Footer.scss';

const Footer = () => {
  const { data } = useContext(DataContext);
  const footerInfos = data?.acf?.option_du_site?.footer_infos;

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-logo">
          <LogoFooter className="footer-logo-svg" aria-label="Logo Courrier Papier" />
        </div>

        <div className="footer-menu">
          <nav>
            <MenuLinks isFooter={true} includeLegal={true} /> {/* Utilisation du composant MenuLinks */}
          </nav>
        </div>

        <div className="footer-right">
          {footerInfos && (
            <>
              <OpeningHours footerInfos={footerInfos} /> {/* Composant pour gérer les horaires */}

            </>
          )}
        </div>
        <div className='footer-right'><div className="footer-contact-info">
          <p className="footer-email">
            <a href={`mailto:${footerInfos.contact}`}>{footerInfos.contact}</a>
          </p>
          <p className="footer-address">
            <a href={footerInfos.lien_de_ladresse_google_map} target="_blank" rel="noopener noreferrer">
              {footerInfos.adresse}
            </a>
          </p>
        </div></div>
      </div>
    </footer>
  );
};

export default Footer;
