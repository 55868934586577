import React, { createContext, useState, useEffect, useCallback } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastModified, setLastModified] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch('https://www.courrierpapier.com/courrierpapier/wp-json/wp/v2/pages/79', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP ! statut : ${response.status}`);
      }

      const result = await response.json();
      const currentModified = result.modified;

      if (lastModified !== currentModified) {
        setData(result);
        setLastModified(currentModified);
      }

      setLoading(false);
      setError(null);
    } catch (err) {
      console.error("Erreur lors de la récupération des données:", err);
      setError(err.message || "Une erreur est survenue lors de la récupération des données");
      setLoading(false);
    }
  }, [lastModified]);

  useEffect(() => {
    fetchData();

    const interval = setInterval(fetchData, 30000);

    return () => clearInterval(interval);
  }, [fetchData]);

  return (
    <DataContext.Provider value={{ data, loading, error }}>
      {children}
    </DataContext.Provider>
  );
};

// Vous pouvez utiliser ce contexte dans vos composants comme ceci :

export const Footer = () => {
  const { data, loading, error } = React.useContext(DataContext);

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur : {error}</div>;
  if (!data || !data.contact) return <div>Aucune donnée de contact disponible</div>;

  return (
    <footer>
      {/* Utilisez data.contact ici */}
      <p>{data.contact}</p>
    </footer>
  );
};

export const WhatsAppChat = () => {
  const { data, loading, error } = React.useContext(DataContext);

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur : {error}</div>;

  const phoneNumber = data?.acf?.option_du_site?.numeros_de_telephone?.replace(/\s/g, '') || '';

  if (!phoneNumber) return <div>Numéro de téléphone non disponible</div>;

  return (
    <div>
      {/* Utilisez phoneNumber ici */}
      <a href={`https://wa.me/${phoneNumber}`}>Contactez-nous sur WhatsApp</a>
    </div>
  );
};