import React, { useContext } from 'react';
import './Apropos.scss';  // Import du fichier SCSS spécifique à la page
import CallToAction from '../../CTA/CallToAction';  // Composant Call to Action
import { DataContext } from '../../../context/DataContext';  // Import du DataContext

const APropos = () => {
  const { data } = useContext(DataContext);  // Utilisation du contexte pour récupérer les données
  const aproposData = data?.acf?.a_propos;  // Récupération des données de la section "À propos"

  if (!aproposData) return null;  // Vérifier si les données existent

  return (
    <section className="apropos-section">
      {/* Section 1: Introduction */}
      <div className="intro-section">
        <div className="intro-container">
          <div className='container'>
            <h1 className="frame-title-big center">{aproposData?.titre || 'À propos de Courrier Papier'}</h1>
            <hr></hr>
            <h3 className="description">
              {aproposData?.introduction || 'Chez Courrier Papier, nous transformons chaque envoi postal en une opportunité de connexion. Depuis plus de 20 ans, nous mettons notre expertise au service des entreprises pour rendre leurs campagnes de communication plus efficaces et plus percutantes.'}
            </h3>
          </div>
        </div>
      </div>

      {/* Boucle sur les items (repeater) */}
      <div className='Wrapper'>
      {aproposData?.items && aproposData.items.map((item, index) => (
        <div key={index} className={`item id${index + 2}`}>
          <div className="section-container">
            <div className='left'>
              <h2 className="title">{item.titre || `Section ${index + 2}`}</h2>
              <p className="subtitle">{item.accroche || 'Sous-titre manquant'}</p>
            </div>
            <div className='right'>
              <p className="description">{item.description || 'Description manquante'}</p>
            </div>
          </div>
          <hr></hr>
        </div>
      ))}
      </div>
      

      {/* Section 6: Call to Action */}
      <CallToAction />

    </section>
  );
};

export default APropos;
