import React, { useEffect } from 'react';
import Modal from 'react-modal';
import './ContactModal.scss'; // Importer le fichier SCSS pour les styles

// Configure React Modal
Modal.setAppElement('#root');

const ContactModal = ({ modalIsOpen, closeModal }) => {
    useEffect(() => {
        // Gérer le scroll de la page lorsque la modal est ouverte
        if (modalIsOpen) {
            document.body.style.overflow = 'hidden'; // Désactive le scroll
        } else {
            document.body.style.overflow = 'auto'; // Active le scroll
        }

        // Réinitialiser le scroll lorsque le composant est démonté
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [modalIsOpen]);

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Formulaire de Contact"
            style={{
                content: {
                    width: '360px', // Largeur standard d'un smartphone
                    height: '640px', // Hauteur standard d'un smartphone
                    margin: 'auto',
                    padding: '0',
                    zIndex: '1000', // Assure que la modal est au-dessus des autres éléments
                    position: 'relative',
                    inset: '0',
                    border: 'none',
                    borderRadius: '8px',
                    overflow: 'hidden', // Cache les dépassements éventuels
                    backgroundColor: '#203737', // Couleur secondaire
                },
                overlay: {
                    zIndex: '999', // Assure que l'overlay est en dessous du contenu de la modal
                    backgroundColor: 'rgba(0, 0, 0, 0.75)', // Ajoute une couleur d'overlay
                }
            }}
        >
            <button className="modal-close-button" onClick={closeModal} aria-label="Fermer la modal">
                &times;
            </button>
            <div className="iframe-container">
                <iframe
                    src="http://dubujpg.cluster027.hosting.ovh.net/courrierpapier/formulaire-contact/"
                    className="responsive-iframe"
                    title="Formulaire de Contact"
                />
            </div>
        </Modal>
    );
};

export default ContactModal;
