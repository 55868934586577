import React, { useContext } from 'react';
import { Link } from 'react-router-dom'; // Import Link pour la navigation
import './CallToAction.scss';
import Button from '../Tools/Button/Button'; // Import du bouton uniformisé
import { DataContext } from '../../context/DataContext'; // Import du contexte pour récupérer les données

const CallToAction = () => {
    const { data } = useContext(DataContext); // Utilisation du contexte pour récupérer les données

    // Récupération des données depuis le JSON
    const ctaData = data?.acf?.cta;

    return (
        <div className="cta-container">
            <div className="cta-content">
                <h1 className="frame-title-big">{ctaData?.titre || 'Prêt à travailler avec nous ?'}</h1>
                <div className='cta-container-description'>
                    <p className="cta-description">
                        {ctaData?.text || 'Contactez-nous dès aujourd\'hui pour en savoir plus sur la façon dont Courrier Papier peut transformer vos envois postaux et améliorer vos résultats.'}
                    </p>
                    <div className="btn">
                        <Link to="/contact" className="contact-btn">
                            <Button variant="third">{ctaData?.texte_bouton || 'Contactez-nous'}</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CallToAction;
