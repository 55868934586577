import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './CookiesBar.scss';

const CookiesBar = () => {
    const [cookiesAccepted, setCookiesAccepted] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const accepted = localStorage.getItem('cookiesAccepted');
        if (!accepted) {
            setIsVisible(true);
        }
    }, []);

    const handleAcceptCookies = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setCookiesAccepted(true);
        setIsVisible(false);
    };

    if (!isVisible || cookiesAccepted) {
        return null;
    }

    return (
        <div className="cookies-bar">
            <div className="cookies-bar-content">
                <p>
                    🍪 Nous utilisons des cookies pour améliorer votre expérience sur notre site. En continuant à naviguer, vous acceptez notre <NavLink to="/politique-confidentialite">politique de confidentialité</NavLink>.
                </p>
                <button className="accept-cookies-button" onClick={handleAcceptCookies}>
                    Accepter
                </button>
            </div>
        </div>
    );
};

export default CookiesBar;
