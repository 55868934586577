// Button.js

import React from 'react';
import './Button.scss';

const Button = ({ children, variant = 'primary', ...props }) => {
  return (
    <button className={`btn ${variant}`} {...props}>
      {children}
    </button>
  );
};

export default Button;
