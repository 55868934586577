import React, { useContext, useRef } from 'react';
import './InspirezVous.scss';
import { DataContext } from '../../../../context/DataContext';
import FrameItem from '../../../Tools/FrameItem/FrameItem';  // Utilisation du même composant FrameItem

const InspirezVous = () => {
  const { data, loading, error } = useContext(DataContext);
  const itemsRef = useRef([]);

  if (loading) return <p>Chargement des données...</p>;
  if (error) return <p>Erreur : {error}</p>;
  if (!data || !data.acf || !data.acf.inspiration || !data.acf.inspiration.item_inspiration) {
    return <p>Aucune donnée disponible</p>;
  }

  const inspirationData = data.acf.inspiration.item_inspiration;

  return (
    <section className="inspirez-vous-section">
      <div className="container">
        <h1 className="inspirez-vous-title frame-title-big left">
          <span className="white-color">Inspirez</span>
          <span className="primary-color">-vous</span>
        </h1>
        <div className="frame-container noBorder">
          {inspirationData.map((item, index) => (
            <FrameItem key={index} item={item} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default InspirezVous;
