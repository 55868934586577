import React from 'react';
import './FrameContainer.scss';  // Import du style spécifique
import FrameItem from './FrameItem'; 

const FrameContainer = ({ items, extraContent, className }) => {
  return (
    <div className={`frame-container ${className || ''}`}>
      {items.map((item, index) => (
        <FrameItem key={index} item={item} index={index} />
      ))}

      {extraContent && (
        <div className="frame-item">
          {extraContent}
        </div>
      )}
    </div>
  );
};

export default FrameContainer;
